import { createApi } from '@reduxjs/toolkit/query/react';
import kosBaseQuery from '../utils/kosBaseQuery';
import { getBaseUrlForKos } from '../utils/baseUrl';

export const commonApiKos = createApi({
    reducerPath: 'commonApiKos',
    baseQuery: kosBaseQuery({
        endpoint: '',
        baseUrl: getBaseUrlForKos(),
    }),
    tagTypes: ['common', 'endpointTag'],
    endpoints: (builder) => ({
        // this one provides Tags method can be anything getting data IS GETTING FROM KOS Java DATA
        getAllFromKos: builder.query({
            query: ({ params, endpoint, method = 'GET', body, urlBase = 'keeplearning/api/' }) => ({
                url: urlBase + endpoint,
                method: method,
                params: params || undefined,
                body: body || undefined,
            }),
            providesTags: (result, error, { endpoint }) => [{ type: 'endpointTag', id: endpoint }],
            keepUnusedDataFor: Infinity,
        }),
    }),
});

export const {
    useGetAllFromKosQuery, // provides tags
    useLazyGetAllFromKosQuery, // provides tags lazily
} = commonApiKos;
