function NotMandatoryIcon() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1.14702 12.2528C0.719859 11.2215 0.5 10.1162 0.5 9C0.5 7.88376 0.719859 6.77846 1.14702 5.74719C1.57419 4.71592 2.20029 3.77889 2.98959 2.98959C3.77889 2.20029 4.71592 1.57419 5.74719 1.14702C6.77846 0.719859 7.88376 0.5 9 0.5C10.1162 0.5 11.2215 0.719859 12.2528 1.14702C13.2841 1.57419 14.2211 2.20029 15.0104 2.98959C15.7997 3.77889 16.4258 4.71592 16.853 5.74719C17.2801 6.77846 17.5 7.88376 17.5 9C17.5 10.1162 17.2801 11.2215 16.853 12.2528C16.4258 13.2841 15.7997 14.2211 15.0104 15.0104C14.2211 15.7997 13.2841 16.4258 12.2528 16.853C11.2215 17.2801 10.1162 17.5 9 17.5C7.88376 17.5 6.77846 17.2801 5.74719 16.853C4.71592 16.4258 3.77889 15.7997 2.98959 15.0104C2.20029 14.2211 1.57419 13.2841 1.14702 12.2528Z'
                stroke='#404040'
            />
            <path
                d='M7 7L11 11M11 7L7 11'
                stroke='#404040'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default NotMandatoryIcon;
