import { Typography, Spin } from 'antd';
import SingleInstitute from './SingleInstitute';
import ReadyToStartBanner from './ReadyToStartBanner';
import { useLazyGetAllQuery } from '../../store/commonApi';
import { useEffect, useRef, useState } from 'react';
import ExploreAllCoursesBanner from './ExploreAllCoursesBanner';
import { useNavigate } from 'react-router-dom';
import buildQueryString from '../../utils/buildQueryString';
const { Title } = Typography;

function ProgramPage() {
    const [allInstitutes, setAllInstitutes] = useState([]);
    const [triggerInstitutes, { isFetching, isLoading }] = useLazyGetAllQuery();
    const completeCopyOfInstitutes = useRef([]);
    const navigate = useNavigate();
    useEffect(() => {
        let QueryString = '?';
        const UserData = JSON.parse(localStorage.getItem('data') || '{}');
        if (UserData === undefined || UserData === null) {
            navigate('/Login');
        }

        // if (UserData.student) {
        //     const allAcademicYearsCode = [];
        //     if (Array.isArray(UserData.student.admissions)) {
        //         UserData.student.admissions.forEach((admission) => {
        //             allAcademicYearsCode.push(admission.academic_year_code);
        //         });
        //     }
        //     QueryString += buildQueryString({
        //         academic_year_codes: allAcademicYearsCode,
        //     });
        //     QueryString += '';
        // } else if (UserData.emplyoee && UserData.adminUserFlag === 'Yes') {
        //     QueryString += '';
        // } else {
        //     navigate('/NotValidUser', {
        //         replace: true,
        //     });
        //     return;
        // }
        triggerInstitutes({
            endpoint: 'institute.php' + QueryString,
        })
            .unwrap()
            .then((response) => {
                if (response.institutes) {
                    setAllInstitutes(response.institutes);
                    completeCopyOfInstitutes.current = response.institutes;
                } else {
                    console.error('Error: ', response);
                }
            })
            .catch((error) => console.error(error));
    }, [navigate, triggerInstitutes]);

    if (isLoading || isFetching || isLoading === undefined || isFetching === undefined) {
        return <Spin />;
    }

    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <Title
                level={2}
                style={{
                    fontSize: '25px',
                    lineHeight: '32px',
                    fontWeight: '700',
                    paddingBottom: '0px',
                    marginBottom: '0px',
                }}
            >
                Academic Catalogue
            </Title>
            <Title
                level={4}
                style={{
                    fontSize: '20px',
                    lineHeight: '25px',
                    fontWeight: '500',
                    paddingTop: '0px',
                    marginTop: '10px',
                }}
            >
                Browse through all our programs and courses to know more about what VU has to offer.
            </Title>
            <div
                style={{
                    marginTop: '2vw',
                }}
            >
                <ExploreAllCoursesBanner />
            </div>
            <div
                style={{
                    marginTop: '30px',
                }}
            >
                <AllTheInstitute allInstitute={allInstitutes} />
            </div>

            <ReadyToStartBanner />
        </div>
    );
}

function AllTheInstitute({ allInstitute }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }}
        >
            {allInstitute?.map((institute) => (
                <SingleInstitute key={institute.name} SingleInfoInstitute={institute} />
            ))}
        </div>
    );
}

export default ProgramPage;
