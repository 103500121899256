import { Spin } from 'antd';
import extractUrlValue from '../../utils/extractUrlValue';
import { useEffect, useMemo } from 'react';
import { useLazyGetAllFromKosQuery } from '../../store/commonApiKos';
import { useNavigate } from 'react-router-dom';
import buildQueryString from '../../utils/buildQueryString';

function SSORedirect() {
    const navigate = useNavigate();
    const [trigger] = useLazyGetAllFromKosQuery();
    const completeUrlString = useMemo(() => {
        return (
            ExtractFromUrl() +
            '&' +
            buildQueryString({
                redirect_url:
                    window.location.origin +
                    '/SSORedirect?name=' +
                    extractUrlValue('name'),
            })
        );
    }, []);

    useEffect(() => {
        trigger({
            endpoint: 'sso_callback' + completeUrlString,
            urlBase: 'spring/connector/',

        })
            .then((response) => {
                if (response.error) {
                    navigate('/');
                } else {
                    localStorage.setItem('data', JSON.stringify(response.data?.data));
                    navigate('/ProgramPage');
                }
            })
            .catch((error) => console.error(error));
    }, [completeUrlString, navigate, trigger]);

    return <Spin size='large' />;
}

function ExtractFromUrl() {
    const url = new URL(window.location.href);
    return url.search;
}

export default SSORedirect;
