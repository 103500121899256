import { Typography, Select } from 'antd';
import BackIcon from '../../../assets/BackIcon';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { CoursePageContext } from '../CoursePageApiAndContext';
import FilterIcon from '../../../assets/FilterIcon';
import { useGetAllQuery } from '../../../store/commonApi';
import LeftChevron from '../../../assets/LeftChevron';
const { Title } = Typography;

function CourseFilter() {
    const navigate = useNavigate();
    const { programName, selectedState } = useContext(CoursePageContext);
    return (
        <div
            style={{
                padding: '20px',
            }}
        >
            <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    paddingLeft: '0px',
                    cursor: 'pointer',
                }}
                onClick={() => navigate(-1)}
            >
                <BackIcon /> Go Back
            </button>
            <Title
                level={3}
                style={{
                    marginTop: '10px',
                    fontWeight: '700',
                    fontSize: '24px',
                    lineHeight: '32px',
                }}
            >
                {selectedState === 'Course' ? 'All Courses' : programName}
            </Title>
            <Filter />
        </div>
    );
}

function Filter() {
    const { selectedState, ResetAllFilters } = useContext(CoursePageContext);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        fontWeight: '700',
                    }}
                >
                    <FilterIcon /> FILTER
                </div>
                <div>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                            cursor: 'pointer',
                            color: 'rgb(166, 42, 55)',
                            fontWeight: '700',
                            fontFamily: 'Satoshi-Variable, sans-serif',
                        }}
                        onClick={() => ResetAllFilters()}
                    >
                        Reset
                    </button>
                </div>
            </div>
            {selectedState === 'Program' ? <YearFilter /> : <SchoolFilter />}
            <LevelFilter />
            <TermFilter />
            <LookingForComponent />
        </div>
    );
}

function SchoolFilter() {
    const { schoolName, setSchoolName, programName } = useContext(CoursePageContext);
    const { data, isFetching, error } = useGetAllQuery({
        endpoint: 'school.php',
        params: {
            program: programName,
        },
    });
    if (error) console.log(error);

    return (
        <>
            <Title
                level={5}
                style={{
                    marginTop: '10px',
                }}
            >
                School
            </Title>
            <Select
                showSearch
                placeholder='Select a school'
                style={{
                    width: '100%',
                }}
                loading={isFetching}
                allowClear={true}
                value={schoolName}
                onChange={(value) => {
                    setSchoolName(value);
                }}
                options={data?.schools?.map((school) => ({
                    label: school,
                    value: school,
                }))}
                mode='multiple'
                maxTagCount={'responsive'}
            />
        </>
    );
}

function LevelFilter() {
    const { level, setLevel, programName } = useContext(CoursePageContext);
    const { data, isFetching, error } = useGetAllQuery({
        endpoint: 'level.php',
        params: {
            program: programName,
        },
    });
    if (error) console.log(error);
    return (
        <>
            <Title
                level={5}
                style={{
                    marginTop: '10px',
                }}
            >
                Level
            </Title>
            <Select
                loading={isFetching}
                showSearch
                placeholder='Select a level'
                style={{
                    width: '100%',
                }}
                allowClear={true}
                options={
                    data?.levels?.map((level) => ({
                        label: level,
                        value: level,
                    })) || []
                }
                value={level}
                onChange={(value) => {
                    setLevel(value);
                }}
                mode='multiple'
                maxTagCount={'responsive'}
            />
        </>
    );
}

function TermFilter() {
    const { term, setTerm } = useContext(CoursePageContext);
    return (
        <>
            <Title
                level={5}
                style={{
                    marginTop: '10px',
                }}
            >
                Term
            </Title>
            <Select
                placeholder='Select a term'
                showSearch
                style={{
                    width: '100%',
                }}
                value={term}
                onChange={(value) => {
                    setTerm(value);
                }}
                allowClear={true}
                mode='multiple'
                maxTagCount={'responsive'}
                options={[
                    {
                        value: 'Independence',
                    },
                    {
                        value: 'Festival',
                    },
                    {
                        value: 'Republic',
                    },
                    {
                        value: 'Colors',
                    },
                ]}
            />
        </>
    );
}

function YearFilter() {
    const { year, setYear, programName } = useContext(CoursePageContext);
    const { data, isFetching, error } = useGetAllQuery({
        endpoint: 'year.php',
        params: {
            program: programName,
        },
    });

    if (error) console.log(error);

    return (
        <>
            <Title
                level={5}
                style={{
                    marginTop: '10px',
                }}
            >
                Year
            </Title>
            <Select
                showSearch
                placeholder='Select a Year'
                style={{
                    width: '100%',
                }}
                allowClear={true}
                options={
                    data?.years?.map((year) => ({
                        label: year,
                        value: year,
                    })) || []
                }
                value={year}
                onChange={(value) => {
                    console.log(value);
                    setYear(value);
                }}
                mode='multiple'
                maxTagCount={'responsive'}
                loading={isFetching}
            />
        </>
    );
}

function LookingForComponent() {
    const { selectedState } = useContext(CoursePageContext);
    const navigate = useNavigate();
    return (
        <div
            style={{
                backgroundColor: '#FFFBE6',
                height: 'fit-content',
                border: '1px solid #FFEBA5',
                borderRadius: '6px',
                marginTop: '20px',
                padding: '10px',
            }}
        >
            <p
                style={{
                    marginTop: '0px',
                }}
            >
                <span
                    style={{
                        fontWeight: '700',
                    }}
                >
                    {selectedState === 'Program'
                        ? 'Looking for all available courses? '
                        : 'Looking for a specific program? '}
                </span>
                {selectedState === 'Program'
                    ? 'Browse through our course catalogue here.'
                    : 'Select a program to know more details'}
            </p>
            <button
                style={{
                    backgroundColor: 'transparent',
                    color: '#A62A37',
                    border: 'none',
                    fontWeight: '700',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    fontFamily: 'Satoshi-Variable, sans-serif',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    if (selectedState === 'Program') {
                        navigate('/CoursePage/Course');
                    } else {
                        navigate('/ProgramPage');
                    }
                }}
            >
                {selectedState === 'Program' ? 'View Courses' : 'View Programs'}
                <svg
                    width='6'
                    height='8'
                    viewBox='0 0 6 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{
                        marginLeft: '5px',
                    }}
                >
                    <path
                        d='M1.5 7L4.5 4L1.5 1'
                        stroke='#A62A37'
                        strokeWidth='1.3'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            </button>
        </div>
    );
}

export default CourseFilter;
