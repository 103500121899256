import { createContext, useEffect, useRef, useState } from 'react';
import { useLazyGetAllQuery } from '../../store/commonApi';
import { notification } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

const CoursePageContext = createContext();

function useCoursePage() {
    const cleanData = useRef([]);
    const navigate = useNavigate();
    const { selectedType, selectedProgram } = useParams();
    if (selectedType === 'Program' && selectedProgram === undefined) {
        navigate('/ProgramPage/');
    }
    const [selectedState, setSelectedState] = useState(selectedType); // Course , Program
    const [programName, setProgramName] = useState(selectedProgram);
    const [schoolName, setSchoolName] = useState([]);
    const [term, setTerm] = useState([]);
    const [level, setLevel] = useState([]);
    const [year, setYear] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [allCourses, setAllCourses] = useState([]);
    const [triggerCourse, { data: coursesData, error: errorData, isLoading: isLoadingData }] =
        useLazyGetAllQuery();

    function ResetAllFilters() {
        setTerm();
        setLevel();
        setYear();
    }

    useEffect(() => {
        setAllCourses(() => {
            let newCourses = structuredClone(cleanData.current);

            if (programName) {
                newCourses = newCourses.filter((course) => course.program_name === programName);
            }
            if (schoolName && schoolName.length > 0) {
                newCourses = newCourses.filter((course) => schoolName.includes(course.school_name));
            }
            if (term && term.length > 0) {
                newCourses = newCourses.filter((course) => term.includes(course.term));
            }
            if (level && level.length > 0) {
                newCourses = newCourses.filter((course) => level.includes(course.level));
            }
            if (year && year.length > 0) {
                newCourses = newCourses.filter((course) => year.includes(course.year));
            }
            if (inputValue) {
                // search by course title or code
                newCourses = newCourses.filter(
                    (course) =>
                        course?.title?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
                        course?.code.toLowerCase()?.includes(inputValue?.toLowerCase()),
                );
            }
            return newCourses;
        });
    }, [selectedState, term, level, year, inputValue, programName, schoolName]);

    useEffect(() => {
        triggerCourse({
            endpoint: 'course.php',
        })
            .unwrap()
            .then((res) => {
                if (res.data && Array.isArray(res.data)) {
                    if (selectedProgram) {
                        setAllCourses(
                            res.data.filter((course) => course.program_name === selectedProgram),
                        );
                        cleanData.current = res.data;
                        return;
                    }
                    cleanData.current = res.data;
                    setAllCourses(res.data);
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to fetch courses',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ', err);
            });
    }, [triggerCourse]);

    useEffect(() => {
        if (selectedProgram) {
            setProgramName(selectedProgram);
        }
    }, [selectedProgram]);

    useEffect(() => {
        if (selectedType) {
            setSelectedState(selectedType);
        }
    }, [selectedType]);

    return {
        selectedState,
        term,
        level,
        year,
        inputValue,
        allCourses,
        coursesData,
        errorData,
        isLoadingData,
        programName,
        schoolName,
        setSchoolName,
        setProgramName,
        setAllCourses,
        setInputValue,
        setYear,
        setLevel,
        setTerm,
        ResetAllFilters,
        setSelectedState,
    };
}

export { useCoursePage, CoursePageContext };
