import { Button, Collapse, Divider, Input, List, Modal, Spin, Tag, Typography } from 'antd';
import SearchIcon from '../../../assets/SearchIcon';
import { useContext, useState } from 'react';
import { CoursePageContext } from '../CoursePageApiAndContext';
import MandatoryIcon from '../../../assets/MandatoryIcon';
import NotMandatoryIcon from '../../../assets/NotMandatoryIcon';
import PropTypes from 'prop-types';
import formatNumber from '../../../utils/twoNumberFormat';
import RotatedTextBox from '../../../components/RotatedBox';
import ViewMoreModal from './ViewMoreModal';
import DownChevron from '../../../assets/DownChevron';
import LeftChevron from '../../../assets/LeftChevron';
import { useGetAllFromKosQuery } from '../../../store/commonApiKos';
import CalenderContent from './ViewCalenderModal';
const { Title, Text } = Typography;
const commonStyleP = {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
};
const commonStyleInfo = {
    fontSize: '14px',
    fontWeight: '600',
    wordWrap: 'break-word',
    marginTop: '-6px',
    lineHeight: '18px',
};

function Courses() {
    return (
        <div
            style={{
                padding: '20px',
            }}
        >
            <SearchBar />
            <Divider />
            <AllCourseList />
        </div>
    );
}

function SearchBar() {
    let { inputValue, setInputValue } = useContext(CoursePageContext);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '40%',
                    marginLeft: 'auto',
                    marginRight: '10px',
                }}
            >
                <Input
                    placeholder='Search for courses'
                    style={{
                        backgroundColor: '#F9F9F9',
                    }}
                    prefix={<SearchIcon />}
                    value={inputValue}
                    size='large'
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </div>
        </div>
    );
}

function AllCourseList() {
    const { allCourses, selectedState, isLoadingData } = useContext(CoursePageContext);

    if (selectedState === 'Program') {
        return (
            <div
                style={{
                    height: window.innerHeight - 250,
                    overflowY: 'auto',
                }}
            >
                {Object.entries(makeProgramList(allCourses))?.map(([year, levelList]) => {
                    return (
                        <div key={year}>
                            <Divider orientation='left' orientationMargin='0'>
                                <Tag>
                                    <NewSpan text={year} />
                                </Tag>
                            </Divider>
                            {Object.entries(levelList)?.map(([level, courses]) => {
                                return (
                                    <div key={level}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '20px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <RotatedTextBox text={level} />
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <List
                                                    itemLayout='horizontal'
                                                    dataSource={courses}
                                                    rowKey={(course) => course.id}
                                                    renderItem={(SingleCourse, index) => {
                                                        return (
                                                            <SingleListItem
                                                                SingleCourse={SingleCourse}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    } else if (selectedState === 'Course') {
        return (
            <List
                itemLayout='horizontal'
                dataSource={allCourses}
                loading={isLoadingData}
                pagination={{
                    pageSize: 7,
                    position: 'bottom',
                }}
                renderItem={(SingleCourse, index) => {
                    return <SingleListItem SingleCourse={SingleCourse} />;
                }}
            />
        );
    } else {
        return <></>;
    }
}

function SingleListItem({ SingleCourse }) {
    const items = [
        {
            key: 'CourseCollapsible',
            label: <SingleCourseItem SingleCourse={SingleCourse} />,
            children: <SingleCourseChild SingleCourse={SingleCourse} />,
            collapsible: 'icon',
        },
    ];
    return (
        <List.Item
            style={{
                display: 'block',
            }}
        >
            <Collapse
                ghost
                items={items}
                expandIcon={({ isActive }) => {
                    return (
                        <div
                            style={{
                                marginTop: '16px',
                            }}
                        >
                            {isActive ? <DownChevron /> : <LeftChevron />}
                        </div>
                    );
                }}
            />
        </List.Item>
    );
}

function SingleCourseChild({ SingleCourse }) {
    const { data, error, isFetching } = useGetAllFromKosQuery({
        endpoint: 'course_catalogue/course',
        params: {
            code: SingleCourse.code,
        },
        urlBase: '/spring/',
    });

    if (isFetching) {
        return (
            <Spin
                size='large'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '140px',
                }}
            />
        );
    }
    if (error) {
        console.error(error);
        return <p></p>;
    }
    const course = data?.courses?.[0];
    return (
        <div
            style={{
                marginLeft: '30px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Text
                style={{
                    fontWeight: '700',
                    fontSize: '14px',
                }}
            >
                Course Aim
            </Text>
            <Text>{course?.course_aim ?? ''}</Text>
        </div>
    );
}

function SingleCourseItem({ SingleCourse }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'baseline',
            }}
        >
            <NameAndTag SingleCourse={SingleCourse} />
            <OtherInfo SingleCourse={SingleCourse} />
        </div>
    );
}

function NameAndTag({ SingleCourse }) {
    // const { selectedState } = useContext(CoursePageContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <div>
            <Title
                level={5}
                style={{
                    marginTop: '0px',
                    textDecoration: SingleCourse.status ? 'underline' : 'none',
                    color: SingleCourse.status ? '#1890ff' : 'black',
                    cursor: SingleCourse.status ? 'pointer' : 'default',
                }}
                onClick={() => {
                    if (SingleCourse.code) {
                        setIsModalOpen(true);
                    }
                }}
            >
                {SingleCourse.title ?? 'Course Unknown'}
            </Title>
            <div>
                <Tag>
                    <NewSpan text={SingleCourse.code} />
                </Tag>
                <Tag>
                    <NewSpan text={SingleCourse.term} />
                </Tag>
                {/* {selectedState === 'Course' ? (
                    <>
                        <Tag>
                            <NewSpan text={SingleCourse.year} />
                        </Tag>
                        <Tag>
                            <NewSpan text={SingleCourse.level} />
                        </Tag>
                    </>
                ) : null} */}
            </div>
            <Modal
                title='Schedule'
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered={true}
                maskClosable={false}
                width={800}
            >
                <CalenderContent SingleCourse={SingleCourse} />
            </Modal>
        </div>
    );
}

function OtherInfo({ SingleCourse }) {
    return (
        <div
            style={{
                display: 'flex',
                gap: '20px',
            }}
        >
            <Mandatory mandatory={SingleCourse.mandatory} />
            <Credit credit={SingleCourse.credit} />
            <PreRequisite SingleCourse={SingleCourse} />
            <ViewMore SingleCourse={SingleCourse} />
        </div>
    );
}

function Mandatory({ mandatory }) {
    const { selectedState } = useContext(CoursePageContext);

    if (selectedState === 'Course') {
        return null;
    }
    return (
        <div>
            <p style={commonStyleP}>Mandatory: </p>
            <div
                style={{
                    marginTop: '-4px',
                    width: '14px',
                }}
            >
                {mandatory === '1' ? <MandatoryIcon /> : <NotMandatoryIcon />}
            </div>
        </div>
    );
}

function Credit({ credit }) {
    return;
    const { selectedState } = useContext(CoursePageContext);
    if (selectedState === 'Course') {
        return null;
    }
    return (
        <div>
            <p style={commonStyleP}>Credit: </p>
            <p style={commonStyleInfo}>{formatNumber(credit)}</p>
        </div>
    );
}

function ViewMore({ SingleCourse }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div
            style={{
                alignSelf: 'center',
                marginRight: '10px',
            }}
        >
            <Button type='default' onClick={() => setIsOpen(true)}>
                View Details
            </Button>
            <ViewMoreModal SingleCourse={SingleCourse} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
}

function PreRequisite({ SingleCourse }) {
    return;
    const { pre_requisites } = SingleCourse;
    const { selectedState } = useContext(CoursePageContext);
    if (selectedState === 'Course') {
        return null;
    }

    return (
        <div
            style={{
                width: '100px',
            }}
        >
            <p style={commonStyleP}>Pre-requisite: </p>
            <SinglePreRequisite pre_requisites={pre_requisites} />
        </div>
    );
}

function SinglePreRequisite({ pre_requisites }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    if (pre_requisites === undefined || pre_requisites === null) {
        return 'None';
    }
    const pre_requisitesLength = pre_requisites?.length;

    if (pre_requisitesLength === 0) {
        return <p style={commonStyleInfo}>None</p>;
    }

    return (
        <div>
            <p style={commonStyleInfo}>{pre_requisites[0]}</p>
            {pre_requisitesLength > 1 ? (
                <button
                    style={{
                        background: 'white',
                        border: 'none',
                        margin: '0',
                        padding: '0',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => setIsModalOpen(true)}
                >
                    <NewSpan text='+' />
                    {pre_requisitesLength - 1}
                </button>
            ) : null}
            <Modal
                title='Pre-requisites'
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered={true}
            >
                {pre_requisites?.map((pre_requisite, index) => {
                    return (
                        <p key={index} style={commonStyleInfo}>
                            {pre_requisite}
                        </p>
                    );
                })}
            </Modal>
        </div>
    );
}

function NewSpan({ text }) {
    return (
        <span
            style={{
                fontWeight: '700',
                background: 'white',
            }}
        >
            {text?.toUpperCase()}
        </span>
    );
}

function makeProgramList(allCourses) {
    // First, group by year
    let programList = {};
    allCourses.forEach((course) => {
        if (!programList[course.year]) {
            programList[course.year] = [course];
        } else {
            programList[course.year].push(course);
        }
    });

    // Sort the years
    let sortedProgramList = {};
    Object.keys(programList)
        .sort()
        .forEach((year) => {
            sortedProgramList[year] = programList[year];
        });

    // Group by level within each year and sort the levels
    for (const year in sortedProgramList) {
        let courses = sortedProgramList[year];
        let levelList = {};
        courses.forEach((course) => {
            if (!levelList[course.level]) {
                levelList[course.level] = [course];
            } else {
                levelList[course.level].push(course);
            }
        });

        // Sort the levels
        let sortedLevelList = {};
        Object.keys(levelList)
            .sort()
            .forEach((level) => {
                sortedLevelList[level] = levelList[level];
            });

        sortedProgramList[year] = sortedLevelList;
    }

    return sortedProgramList;
}

SingleCourseItem.propTypes = {
    SingleCourse: PropTypes.object,
};

NameAndTag.propTypes = {
    SingleCourse: PropTypes.object,
};

OtherInfo.propTypes = {
    SingleCourse: PropTypes.object,
};

Mandatory.propTypes = {
    mandatory: PropTypes.string,
};

Credit.propTypes = {
    credit: PropTypes.string,
};

NewSpan.propTypes = {
    text: PropTypes.string,
};

ViewMore.propTypes = {
    SingleCourse: PropTypes.object,
};

export default Courses;
