import React from 'react';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './styles/KosStyles.css';
import { ConfigProvider } from 'antd';
import kosTheme from './theme.json';
import ReactDOM from 'react-dom/client';

function Index() {
    return (
        <ConfigProvider theme={kosTheme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ConfigProvider>
    );
}

// Get the root element from the HTML document
const RootHTMLElement = document.getElementById('root');
// Create a root react element
const root = ReactDOM.createRoot(RootHTMLElement);
// Render the root react element
root.render(<Index />);
