function FilterIcon() {
    return (
        <svg
            width='10'
            height='10'
            viewBox='0 0 10 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1 1H9V2.086C8.99994 2.35119 8.89455 2.60551 8.707 2.793L6.5 5V8.5L3.5 9.5V5.25L1.26 2.786C1.09272 2.60196 1.00003 2.3622 1 2.1135V1Z'
                stroke='#A62A37'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default FilterIcon;
