import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rtkQueryErrorLogger } from '../utils/rtkQueryErrorLogger';
import { commonApi } from './commonApi';
import { commonApiKos } from './commonApiKos';

export const store = configureStore({
    reducer: {
        [commonApi.reducerPath]: commonApi.reducer,
        [commonApiKos.reducerPath]: commonApiKos.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(commonApi.middleware)
            .concat(commonApiKos.middleware)
            .concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);
