import MainHeader from './MainHeader';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
const { Content, Header } = Layout;

function PageLayout({ children, hideName = false }) {
    return (
        <Layout
            style={{
                background: 'white',
            }}
        >
            <Header
                style={{
                    zIndex: 1,
                    background: 'white',
                    borderBottom: '20px solid #A62A37',
                    height: '90px',
                }}
            >
                <MainHeader hideName={hideName} />
            </Header>
            <Layout
                style={{
                    background: 'white',
                }}
            >
                <Layout
                    style={{
                        overflow: 'auto',
                        marginLeft: '10px',
                        marginTop: '10px',
                        marginRight: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'white',
                    }}
                >
                    <Content>{children}</Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default PageLayout;

PageLayout.propTypes = {
    children: PropTypes.node,
};
