import { Button, Spin, Typography } from 'antd';
import DownloadIcon from '../../assets/DownloadIcon';
import { useGetAllQuery, useLazyGetAllQuery } from '../../store/commonApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import buildQueryString from '../../utils/buildQueryString';
const { Text } = Typography;

function ReadyToStartBanner() {
    // const navigate = useNavigate();
    // const [files, setFiles] = useState([]);
    const {
        data: files,
        isFetching,
        isLoading,
        error,
    } = useGetAllQuery({
        endpoint: 'support_files.php',
    });

    // useEffect(() => {
    //     let QueryString = '?';
    //     const UserData = JSON.parse(localStorage.getItem('data') || '{}');
    //     if (UserData === undefined || UserData === null) {
    //         navigate('/');
    //     }
    //     if (UserData.student) {
    //         const allAcademicYearsCode = [];
    //         if (Array.isArray(UserData.student.admissions)) {
    //             UserData.student.admissions.forEach((admission) => {
    //                 allAcademicYearsCode.push(admission.academic_year_code);
    //             });
    //         }
    //         QueryString += buildQueryString({
    //             academic_year_codes: allAcademicYearsCode,
    //         });
    //     } else if (UserData.emplyoee && UserData.adminUserFlag === 'Yes') {
    //         QueryString += '';
    //     } else {
    //         navigate('/NotValidUser', {
    //             replace: true,
    //         });
    //         return;
    //     }

    //     triggerFiles({
    //         endpoint: 'support_files.php' + QueryString,
    //     })
    //         .unwrap()
    //         .then((response) => {
    //             if (response.data) {
    //                 setFiles(response.data);
    //             } else {
    //                 console.error('Error: ', response);
    //             }
    //         })
    //         .catch((error) => console.error(error));
    // }, [navigate, triggerFiles]);

    if (isLoading || isFetching || isLoading === undefined || isFetching === undefined) {
        return <Spin />;
    }

    if (error) {
        console.error('Error: ', error);
        return <div>Error: {error?.message}</div>;
    }

    // If there are no files to display, return null
    if (files?.data?.length === 0) {
        return <></>;
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
            }}
        >
            <div
                style={{
                    backgroundColor: '#000341',
                    width: '70%',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderRadius: '10px',
                    textAlign: 'left',
                }}
            >
                <Text
                    style={{
                        color: '#FFFFFF',
                        fontSize: '20px',
                        lineHeight: '25px',
                        fontWeight: '700',
                    }}
                >
                    <span> Ready to get started?</span>
                    <span
                        style={{
                            fontWeight: '400',
                            marginLeft: '2px',
                        }}
                    >
                        Download our Academic Guidelines to learn more about our programs
                    </span>
                </Text>
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                    }}
                >
                    {files?.data?.map((file) => {
                        return (
                            <Button
                                key={file.file_url + file.file_name}
                                size='large'
                                style={{
                                    backgroundColor: '#A62A37',
                                    border: 'none',
                                    fontWeight: 800,
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                }}
                                icon={<DownloadIcon />}
                                onClick={() => {
                                    window.open(file.file_url, '_blank');
                                }}
                            >
                                {file.name}
                            </Button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default ReadyToStartBanner;
