import { useNavigate } from 'react-router-dom';
import { CoursePageContext, useCoursePage } from './CoursePageApiAndContext';
import CourseFilter from './components/CourseFilter';
import Courses from './components/Courses';

function CoursePage() {
    const navigate = useNavigate();
    if (localStorage.getItem('data') === null) {
        navigate('/');
    }
    const api = useCoursePage();
    return (
        <CoursePageContext.Provider value={api}>
            <CoursePageSubComponent />
        </CoursePageContext.Provider>
    );
}

function CoursePageSubComponent() {
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 3fr',
            }}
        >
            <CourseFilter />
            <Courses />
        </div>
    );
}

export default CoursePage;
