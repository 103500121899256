function BackIcon() {
    return (
        <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.5 1L1.5 4L4.5 7'
                stroke='black'
                strokeWidth='1.3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default BackIcon;
