import { Typography } from 'antd';

function PageNotFound() {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <Typography.Title level={1}>404 Page Not Found</Typography.Title>
        </div>
    );
}

export default PageNotFound;
