import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import ProgramPage from '../pages/ProgramPage/ProgramPage';
import CoursePage from '../pages/CoursePage/CoursePage';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import Login from '../pages/Login/Login';
import SSORedirect from '../pages/SSORedirect/SSORedirect';

function KosRouterComponent() {
    return (
        <Router>
            <Routes>
                <Route
                    path={'/ProgramPage/'}
                    element={
                        <PageLayout>
                            <ProgramPage />
                        </PageLayout>
                    }
                />
                <Route
                    path={'/CoursePage/:selectedType/:selectedProgram?'}
                    element={
                        <PageLayout>
                            <CoursePage />
                        </PageLayout>
                    }
                />
                <Route
                    path={'*'}
                    element={
                        <PageLayout>
                            <PageNotFound />
                        </PageLayout>
                    }
                />
                <Route
                    path={'/'}
                    element={
                        <PageLayout hideName={true}>
                            <Login />
                        </PageLayout>
                    }
                />
                <Route
                    path={'/SSORedirect'}
                    element={
                        <PageLayout hideName={true}>
                            <SSORedirect />
                        </PageLayout>
                    }
                />
            </Routes>
        </Router>
    );
}

export default KosRouterComponent;
