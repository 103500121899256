function LeftChevron() {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='0.5'
                y='19.5'
                width='19'
                height='19'
                rx='9.5'
                transform='rotate(-90 0.5 19.5)'
                stroke='#DDDDDD'
            />
            <path
                d='M8.5 13L11.5 10L8.5 7'
                stroke='#A62A37'
                strokeWidth='1.3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default LeftChevron;
