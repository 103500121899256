import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Title } = Typography;

function SingleInstitute({ SingleInfoInstitute }) {
    return (
        <div
            style={{
                boxShadow: '0px 5px 13px 0px #0000001F',
                width: '256px',
                height: '280px',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                textAlign: 'left',
                padding: '20px',
                margin: '10px',
                overflow: 'auto',
            }}
        >
            <Title
                level={4}
                style={{
                    fontWeight: '700',
                    marginTop: '0px',
                }}
            >
                {SingleInfoInstitute.name}
            </Title>
            <AllPrograms programs={SingleInfoInstitute.programs} />
        </div>
    );
}

function AllPrograms({ programs }) {
    const navigate = useNavigate();

    return (
        <div>
            {programs?.map((program) => (
                <Title
                    level={5}
                    key={program}
                    style={{
                        marginTop: '10px',
                        cursor: 'pointer',
                        fontWeight: '500',
                    }}
                    onClick={() => {
                        navigate('/CoursePage/Program/' + program);
                    }}
                    onMouseOver={(e) => {
                        e.target.style.textDecoration = 'underline';
                    }}
                    onMouseOut={(e) => {
                        e.target.style.textDecoration = 'none';
                    }}
                >
                    {program}
                </Title>
            ))}
        </div>
    );
}

AllPrograms.propTypes = {
    programs: PropTypes.array,
};

SingleInstitute.propTypes = {
    SingleInfoInstitute: PropTypes.object,
};

export default SingleInstitute;
