import { Button, Modal, Spin, Tag, Typography, Tabs, Divider } from 'antd';
import { useGetAllFromKosQuery } from '../../../store/commonApiKos';
import PropTypes from 'prop-types';
import CalenderContent from './ViewCalenderModal';
import DownloadIcon from '../../../assets/DownloadIcon';
const { Title, Text } = Typography;

function ViewMoreModal({ SingleCourse, isOpen, setIsOpen }) {
    return (
        <Modal
            title={SingleCourse.title}
            open={isOpen}
            onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            centered={true}
            maskClosable={false}
            footer={null}
            width={'724px'}
        >
            <ModalContent SingleCourse={SingleCourse} />
        </Modal>
    );
}

function ModalContent({ SingleCourse }) {
    const items = [
        {
            key: 'CourseDetails',
            label: 'Overview',
            children: <CourseDetails SingleCourse={SingleCourse} />,
        },
        {
            key: 'Calendar',
            label: 'Calendar',
            children: <CalenderContent SingleCourse={SingleCourse} />,
        },
    ];
    return <Tabs defaultActiveKey='CourseDetails' style={{ marginTop: '10px' }} items={items} />;
}

function CourseDetails({ SingleCourse }) {
    const {
        data: moreCourseData,
        isLoading: moreCourseLoading,
        isError: moreCourseError,
    } = useGetAllFromKosQuery({
        endpoint: 'course_catalogue/course',
        params: {
            code: SingleCourse.code,
        },
        urlBase: '/spring/',
    });
    const moreDetails = moreCourseData?.courses?.[0];

    if (moreCourseLoading) {
        return <Spin />;
    }

    if (moreCourseError) {
        console.error('Error: ', moreCourseError);
        return <div>Error: {moreCourseError?.message}</div>;
    }

    if (moreDetails === undefined) return <Title level={5}>No Course Found</Title>;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}
        >
            <div>
                {moreDetails?.course_class_types?.map((CourseClassType) => {
                    return (
                        <Tag
                            key={CourseClassType.class_type_id}
                            style={{
                                backgroundColor: 'white',
                                fontWeight: '700',
                            }}
                        >
                            {CourseClassType.class_type_name?.toUpperCase()}
                        </Tag>
                    );
                })}
            </div>
            <CourseRequites SingleCourse={SingleCourse} />
            <CourseOfferedCredit SingleCourse={moreDetails} />
        </div>
    );
}

function CourseRequites({ SingleCourse }) {
    const {
        data: courseRequisitesData,
        isLoading: courseRequisitesLoading,
        isError: courseRequisitesError,
    } = useGetAllFromKosQuery({
        endpoint: 'course_catalogue/course_requisite',
        params: {
            course_code: SingleCourse.code,
        },
        urlBase: '/spring/',
    });

    if (courseRequisitesLoading) {
        return <Spin />;
    }

    if (courseRequisitesError) {
        console.error('Error: ', courseRequisitesError);
        return;
    }

    return (
        <div>
            <Text
                style={{
                    fontWeight: '700',
                    fontSize: '12px',
                }}
            >
                Requisites
            </Text>
            {courseRequisitesData?.course_requisites?.length === 0 ? (
                <div>
                    <Text>No course requisites</Text>
                </div>
            ) : (
                <CourseRequitesTable courseRequisitesData={courseRequisitesData} />
            )}
        </div>
    );
}

function CourseRequitesTable({ courseRequisitesData }) {
    return (
        <div
            className='KosFormLayout'
            style={{
                marginTop: '10px',
            }}
        >
            {courseRequisitesData?.course_requisites?.map((CourseRequisite) => {
                return (
                    <div
                        key={CourseRequisite.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '12px',
                            }}
                        >
                            {CourseRequisite.relation}
                        </p>
                        <p
                            style={{
                                fontWeight: '700',
                                margin: '0px',
                                fontSize: '14px',
                            }}
                        >
                            {CourseRequisite.requisite_course_code +
                                ' ' +
                                CourseRequisite.requisite_course_name}
                        </p>
                    </div>
                );
            })}
        </div>
    );
}

function CourseOfferedCredit({ SingleCourse }) {
    return (
        <div>
            <Text
                style={{
                    fontWeight: '700',
                    fontSize: '12px',
                }}
            >
                Offered Credits
            </Text>
            <div
                style={{
                    border: '1px solid #D9D9D9',
                    borderRadius: '6px',
                    padding: '10px',
                }}
            >
                {SingleCourse?.offered_courses?.map((SingleOfferedCourse, index) => {
                    const isOdd = index % 2 === 0;
                    const isNotLast = index !== SingleCourse.offered_courses.length - 1;

                    return (
                        <>
                            <div
                                key={SingleOfferedCourse.id}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: '20px',
                                    padding: '10px',
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontWeight: '700',
                                            margin: '0px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {SingleOfferedCourse.total_credits} Credits
                                    </p>
                                    <p
                                        style={{
                                            fontWeight: '400',
                                            margin: '0px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Term Span: {SingleOfferedCourse.term_span}{' '}
                                    </p>
                                </div>
                                <div>
                                    {SingleOfferedCourse?.offered_course_outlines &&
                                    Array.isArray(SingleOfferedCourse.offered_course_outlines) &&
                                    SingleOfferedCourse.offered_course_outlines.length > 0 ? (
                                        <ShowButton
                                            offered_course_outlines={
                                                SingleOfferedCourse.offered_course_outlines
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                            {isOdd && isNotLast ? (
                                <Divider
                                    style={{
                                        margin: '0px',
                                        color: '#000000',
                                    }}
                                />
                            ) : (
                                <> </>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
}

function ShowButton({ offered_course_outlines }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                flexWrap: 'wrap',
            }}
        >
            {offered_course_outlines?.map((offered_course_outline, index) => {
                return (
                    <Button
                        key={offered_course_outline.id}
                        size='middle'
                        style={{
                            fontSize: '14px',
                            marginBottom: '5px',
                            color: '#A62A37',
                        }}
                        onClick={() => {
                            window.open(offered_course_outline.file_url, '_blank');
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <DownloadIcon fill='#A62A37' />
                            {'Download Course Outline ' + (index + 1) + ' '}
                        </div>
                    </Button>
                );
            })}
        </div>
    );
}

export default ViewMoreModal;

ViewMoreModal.propTypes = {
    SingleCourse: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
};

CourseOfferedCredit.propTypes = {
    SingleCourse: PropTypes.object.isRequired,
};

CourseRequites.propTypes = {
    SingleCourse: PropTypes.object.isRequired,
};

ModalContent.propTypes = {
    SingleCourse: PropTypes.object.isRequired,
};
