import { isRejectedWithValue } from '@reduxjs/toolkit';
import { notification } from 'antd';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        const { status, data } = action.payload;
        if (action.type !== 'authenticate/executeMutation/rejected') {
            if (status === 'FETCH_ERROR') {
                window.location.href = '/ServerFailed';
            } else if (status === 401) {
                // REMOVE ALL DATA
                localStorage.removeItem('data');
            } else if (data?.message && typeof data.message === 'string') {
                // TOAST
                notification.error({
                    placement: 'bottomRight',
                    message: data.message,
                });
            } else {
                // TOAST
                notification.error({
                    placement: 'bottomRight',
                    message: 'Something went wrong! Please try again later.',
                });
            }
        }
    }
    return next(action);
};
