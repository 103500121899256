import PropTypes from 'prop-types';

const RotatedTextBox = ({ text }) => {
    return (
        <div className='box'>
            <div className='rotated-text'>{text}</div>
        </div>
    );
};

RotatedTextBox.propTypes = {
    text: PropTypes.string,
};

export default RotatedTextBox;
