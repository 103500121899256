import { createApi } from '@reduxjs/toolkit/query/react';
import kosBaseQuery from '../utils/kosBaseQuery';

export const commonApi = createApi({
    reducerPath: 'common',
    baseQuery: kosBaseQuery({
        endpoint: '',
    }),
    tagTypes: ['common', 'endpointTag'],
    endpoints: (builder) => ({
        // this one provides Tags method can be anything getting data IS GETTING FROM PHP DATA
        getAll: builder.query({
            query: ({ params, endpoint, method = 'GET', body }) => ({
                url: endpoint,
                method: method,
                params: params || undefined,
                body: body || undefined,
            }),
            providesTags: (result, error, { endpoint }) => [{ type: 'endpointTag', id: endpoint }],
            keepUnusedDataFor: Infinity,
        }),
    }),
});

export const {
    useGetAllQuery, // provides tags
    useLazyGetAllQuery, // provides tags lazily
} = commonApi;
