function DownChevron() {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='#DDDDDD' />
            <path
                d='M7 8.5L10 11.5L13 8.5'
                stroke='#A62A37'
                strokeWidth='1.3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default DownChevron;
