import React from 'react';
import { Form, Input, Button, Typography, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import './UserLogin.css';
import { useGetAllFromKosQuery, useLazyGetAllFromKosQuery } from '../../store/commonApiKos';
import buildQueryString from '../../utils/buildQueryString';
const { Title } = Typography;

function Login() {
    return (
        <div className='UserLogin-container'>
            <MainLoginForm />
        </div>
    );
}

function MainLoginForm() {
    const [loginForm] = Form.useForm();
    const [loginTrigger, { isLoading }] = useLazyGetAllFromKosQuery();

    const navigate = useNavigate();

    const onFinish = (values) => {
        const InvalidCred = ['Invalid credentials. Please try again.'];
        loginTrigger({
            endpoint: 'authenticate',
            body: values,
            method: 'POST',
            urlBase: 'spring/user_verification/',
        })
            .unwrap()
            .then((response) => {
                if (response.jwt_token) {
                    localStorage.setItem('data', JSON.stringify(response.data));
                    navigate('/ProgramPage/');
                } else {
                    setLoginInvalid(loginForm, InvalidCred);
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    setLoginInvalid(loginForm, InvalidCred);
                } else {
                    setLoginInvalid(loginForm, [
                        'An error occurred. Please try again later or contact the system administrator.',
                    ]);
                }
            });
    };

    return (
        <div className='UserLogin-form-container'>
            <div className='UserLogin-form'>
                <Title
                    level={2}
                    style={{
                        fontWeight: '700',
                    }}
                >
                    Login
                </Title>
                <Title
                    level={5}
                    style={{
                        fontWeight: '400',
                    }}
                >
                    Welcome to Keep Learning
                </Title>
                <Form
                    name='login'
                    onFinish={onFinish}
                    autoComplete='off'
                    labelAlign='left'
                    form={loginForm}
                    layout='vertical'
                    size='large'
                >
                    <Form.Item
                        label={'Username'}
                        name='user_name'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={'Password'}
                        name='secret_key'
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name='submitButton'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={isLoading}
                            style={{
                                width: '100%',
                            }}
                        >
                            {isLoading ? 'Logging in...' : 'Login'}
                        </Button>
                    </Form.Item>
                </Form>
                <LoginSSO />
            </div>
        </div>
    );
}

function LoginSSO() {
    const { data, isLoading, error } = useGetAllFromKosQuery({
        endpoint: 'connector/sso',
        urlBase: 'spring/',
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) console.error(error);

    return (
        <div className='AllSsoClients'>
            {data?.objects?.map((client) => {
                return <SingleSsoClientComponent client={client} key={client.id} />;
            })}
        </div>
    );
}

function SingleSsoClientComponent({ client }) {
    const [redirectTrigger] = useLazyGetAllFromKosQuery();

    function onClick() {
        redirectTrigger({
            endpoint: 'connector/sso_login',
            urlBase: 'spring/',
            params: {
                id: client.id,
                redirect_url:
                    window.location.origin +
                    '/SSORedirect?' +
                    buildQueryString({
                        name: client.system_connector.name,
                    }),
            },
        })
            .unwrap()
            .then((response) => {
                if (response.sso_login.redirect_url) {
                    window.location.href = response.sso_login.redirect_url;
                } else if (response.message) {
                    notification.error({
                        message: response.message,
                        placement: 'bottomRight',
                    });
                } else {
                    console.error('No Sso Clients Found');
                }
            })
            .catch((error) => console.error(error));
    }

    return (
        <Button type='default' onClick={onClick} style={{ width: '100%', marginBottom: '10px' }}>
            <img
                src={client.system_connector.logo}
                alt={client.name}
                style={{
                    width: '25px',
                    height: '25px',
                    marginTop: '-2px',
                }}
            />
        </Button>
    );
}

function setLoginInvalid(loginForm, errors) {
    loginForm.setFields([
        {
            name: 'secret_key',
            errors: errors,
        },
    ]);
}

export default Login;
