function SearchIcon() {
    return (
        <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_6_142)'>
                <path
                    d='M1.5 5C1.5 5.45963 1.59053 5.91475 1.76642 6.33939C1.94231 6.76403 2.20012 7.14987 2.52513 7.47487C2.85013 7.79988 3.23597 8.05769 3.66061 8.23358C4.08525 8.40947 4.54037 8.5 5 8.5C5.45963 8.5 5.91475 8.40947 6.33939 8.23358C6.76403 8.05769 7.14987 7.79988 7.47487 7.47487C7.79988 7.14987 8.05769 6.76403 8.23358 6.33939C8.40947 5.91475 8.5 5.45963 8.5 5C8.5 4.54037 8.40947 4.08525 8.23358 3.66061C8.05769 3.23597 7.79988 2.85013 7.47487 2.52513C7.14987 2.20012 6.76403 1.94231 6.33939 1.76642C5.91475 1.59053 5.45963 1.5 5 1.5C4.54037 1.5 4.08525 1.59053 3.66061 1.76642C3.23597 1.94231 2.85013 2.20012 2.52513 2.52513C2.20012 2.85013 1.94231 3.23597 1.76642 3.66061C1.59053 4.08525 1.5 4.54037 1.5 5Z'
                    stroke='#868686'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M10.5 10.5L7.5 7.5'
                    stroke='#868686'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_6_142'>
                    <rect width='12' height='12' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SearchIcon;
