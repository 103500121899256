function DownloadIcon({ fill = 'white' }) {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_6_212)'>
                <path
                    d='M2.66663 11.3334V12.6667C2.66663 13.0203 2.8071 13.3595 3.05715 13.6095C3.3072 13.8596 3.64634 14 3.99996 14H12C12.3536 14 12.6927 13.8596 12.9428 13.6095C13.1928 13.3595 13.3333 13.0203 13.3333 12.6667V11.3334'
                    stroke={fill}
                    strokeWidth='1.3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M4.66663 7.33337L7.99996 10.6667L11.3333 7.33337'
                    stroke={fill}
                    strokeWidth='1.3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M8 2.66663V10.6666'
                    stroke={fill}
                    strokeWidth='1.3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_6_212'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default DownloadIcon;
