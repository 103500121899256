import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from './baseUrl';
import buildQueryString from './buildQueryString';

function kosBaseQuery({ endpoint = '', baseUrl = getBaseUrl() }) {
    if (!baseUrl) {
        throw new Error('Base URL is not defined');
    }

    const baseQuery = fetchBaseQuery({
        baseUrl: baseUrl + endpoint,
    });

    return async (args, api, extraOptions) => {
        let url = typeof args === 'string' ? args : args.url;
        if (typeof args === 'object' && 'params' in args && args.params) {
            const queryString = buildQueryString(args.params);
            url = `${url}?${queryString}`;
            args = { ...args, url, params: undefined };
        }
        return baseQuery(args, api, extraOptions);
    };
}

export default kosBaseQuery;
