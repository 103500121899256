import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
const { Text } = Typography;

function ExploreAllCoursesBanner() {
    return (
        <div
            style={{
                border: '1px solid #FFEBA5',
                background: '#fffbe6',
                width: 'fit-content',
                padding: '13px',
                margin: 'auto',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Text
                style={{
                    fontWeight: '700',
                    fontSize: '16px',
                }}
            >
                Looking for all available courses?
            </Text>
            <Text
                style={{
                    fontSize: '16px',
                    marginLeft: '5px',
                }}
            >
                Browse through our course catalogue here.
            </Text>
            <div
                style={{
                    marginLeft: '20px',
                }}
            >
                <Link to='/CoursePage/Course'>
                    <Button
                        style={{
                            background: '#A62A37',
                            color: 'white',
                            fontWeight: '700',
                        }}
                    >
                        Explore all Courses
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default ExploreAllCoursesBanner;
